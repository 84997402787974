export default {
  __default: {
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
  },
  body: { py: 2 },
  container: { pt: 1 },
  image: {
    overflow: 'hidden',
    '@media (hover: hover)': {
      '[data-even=false] &:hover img': { '--scale': '1.05', '--rotate': '-0.5deg' },
      '[data-even=true] &:hover img': { '--scale': '1.05', '--rotate': '0.5deg' },
    },
    img: {
      willChange: 'transform',
      transform: 'scale(var(--scale, 1)) rotate(var(--rotate, 0deg))',
      transition: 'transform var(--ui-duration) var(--ui-ease)',
    },
  },
  head: {
    gridTemplateColumns: '1fr auto',
    gridColumnGap: 1,
    gridRowGap: '0.5em',
    pb: 'var(--gap)',

    '.date': {
      justifySelf: 'end',
    },
  },
}
