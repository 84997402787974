const duration = {
  list: [1, 0.4, 0.35],
  index: [1, 0.6, 0.15],
}

export default {
  duration,
  defaultTransition: 'fade',
  variantKeys: { initial: 'initial', animate: 'enter', exit: 'exit' },

  reset: {
    y: 0,
    skewX: 0,
    skewY: 0,
    opacity: 0,
  },

  // Cards view
  cards: {
    initial: {
      y: 25,
      opacity: 0,
      willChange: 'auto',
    },
    enter: {
      y: 0,
      opacity: 1,
      transition: { duration: duration.index[0], delay: duration.index[2], ease: 'circOut' },
    },
    exit: {
      y: 4,
      opacity: 0,
      transition: { duration: duration.index[1], ease: 'circOut' },
    },
  },

  // List view
  list: {
    initial: {
      y: 15,
      opacity: 0,
      willChange: 'auto',
      pointerEvents: 'none',
    },
    enter: {
      y: 0,
      opacity: 1,
      transition: { duration: duration.list[0], delay: duration.list[2], ease: 'circOut' },
      transitionEnd: {
        pointerEvents: 'all',
      },
    },
    exit: {
      opacity: 0,
      pointerEvents: 'none',
      transition: { duration: duration.list[1], ease: 'circOut' },
    },
  },

  // Text view
  text: {
    initial: {
      y: 15,
      skewX: -0.65,
      opacity: 0,
      willChange: 'auto',
      pointerEvents: 'none',
      transformOrigin: 'top right',
    },
    enter: {
      y: 0,
      skewX: 0,
      opacity: 1,
      transition: { duration: duration.list[0], delay: duration.list[2], ease: 'circOut' },
      transitionEnd: {
        pointerEvents: 'all',
      },
    },
    exit: {
      opacity: 0,
      pointerEvents: 'none',
      transition: { duration: duration.list[1], ease: 'circOut' },
    },
  },

  // Simple Fade
  fade: {
    initial: {
      y: 0,
      opacity: 0,
      willChange: 'auto',
      pointerEvents: 'none',
    },
    enter: {
      y: 0,
      opacity: 1,
      transition: { duration: duration.list[0], delay: duration.list[2], ease: 'circOut' },
      transitionEnd: {
        pointerEvents: 'all',
      },
    },
    exit: {
      opacity: 0,
      pointerEvents: 'none',
      transition: { duration: duration.list[1], ease: 'circOut' },
    },
  },

  // Toolbars
  tools: {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: { duration: 0.5, ease: 'circOut' },
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.5, ease: 'circOut' },
    },
  },

  // Lazy Transition
  lazy: (prop = 'skewX') => ({
    initial: {
      y: 15,
      [prop]: -4,
      opacity: 0,
    },
    animate: {
      y: 0,
      [prop]: 0,
      opacity: 1,
      transition: {
        duration: duration.list[0],
        delay: duration.list[2],
        ease: 'circOut',
      },
    },
  }),

  // Lazy Fade
  lazyFade: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        duration: duration.list[0],
        delay: duration.list[2],
        ease: 'circOut',
      },
    },
  },

  // Scoll Defaults
  defaults: {
    duration: duration.list[0],
    nativeDuration: duration.list[0] * 1000,
  },
}
