import { atom, useRecoilState } from 'recoil'

const menuOpenState = atom({
  key: 'menuOpenState',
  default: undefined,
})

const useMenuState = () => useRecoilState(menuOpenState)

export default useMenuState
