import { memo, forwardRef } from 'react'
import { Flex } from 'theme-ui'

import { Cloudinary } from '@cloudinary/base'
import { AdvancedVideo } from '@cloudinary/react'
import { inset } from '@monobits/core'

import { getMedia, getCloudinaryProps } from './utils'
import * as tr from './transformations'

const BASE = {
  playsInline: true,
  'webkit-playsinline': 'true',
  preload: 'auto',
  muted: true,
  autoPlay: true,
}

const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.GATSBY_CLOUDINARY_CLOUD_NAME,
    url: { secure: !!(process.env.NODE_ENV === 'production') },
  },
})

/**
 * https://cloudinary.com/documentation/sdks/js/frontend-frameworks/index.html
 * @example <CloudImage sx={{ width: 300 }} ratio="contain" fit="cover" url={image?.filename} medias={medias} />
 */
const CloudVideo = forwardRef(
  ({ url, name, medias = {}, attributes, ratio, fit = 'cover', modifiers, sx, ...props }, ref) => {
    const attr = { ...BASE, ...attributes }

    let cloudProps, media, source

    if (name) {
      source = cld.image(name)
      media = {}
    } else {
      cloudProps = getCloudinaryProps(url)
      media = getMedia(medias, cloudProps.filename)
      source = cld.image(cloudProps.filename)
    }

    if (modifiers) {
      modifiers(source, tr)
    } else {
      source.addTransformation(tr.optimize())
    }

    let poster = source.addTransformation(tr.optimize()).toURL()

    const video = <AdvancedVideo cldVid={source} {...attr} poster={poster} />

    return 'width' in media || ratio ? (
      <Flex
        ref={ref}
        {...props}
        data-aspect-ratio
        sx={{
          '--aspect-ratio': ratio ? ratio : media.aspectRatio,
          position: 'relative',

          video: {
            size: '100%',
            objectFit: fit,
            position: 'absolute',
            ...inset(0),
          },
          ...sx,
        }}
      >
        {video}
      </Flex>
    ) : (
      <Flex ref={ref} sx={{ video: { size: '100%', objectFit: fit } }} {...props}>
        {video}
      </Flex>
    )
  }
)

export default memo(CloudVideo)
