import { forwardRef, useRef } from 'react'
import { Box } from 'theme-ui'
import { useConditionalEffect } from '@react-hookz/web'
import { fitAndPosition } from 'object-fit-math'
import { gsap } from 'gsap'

import useDebouncedRect from './useDebouncedRect'

const useFitAnything = ({ fit = 'cover', refreshRate = 500 }, setProperties = true) => {
  let target = useRef(null)
  let container = useRef(null)
  let aspect = useRef(null)

  const rect = useDebouncedRect(container?.current, refreshRate)

  let fits = {}

  useConditionalEffect(
    () => {
      let view = gsap.getProperty(container.current)
      let size = gsap.getProperty(aspect.current)

      fits = fitAndPosition(
        { width: view('width'), height: view('height') },
        { width: size('width'), height: size('height') },
        fit
      )

      if (setProperties) gsap.set(target.current, fits)
    },
    [rect?.width, rect?.height],
    [container, aspect, target, 'width' in rect]
  )

  return {
    fits,
    refs: { container, aspect, target },
  }
}

export const Target = forwardRef(({ sx, ...props }, ref) => (
  <Box ref={ref} sx={{ size: '100%', position: 'absolute', top: 0, left: 0, ...sx }} {...props} />
))
export const Aspect = forwardRef(({ children, sx, ratio, ...props }, ref) => (
  <div
    ref={ref}
    data-aspect-ratio
    sx={{
      visibility: 'hidden',
      position: 'absolute',
      width: '100%',
      top: 0,
      left: 0,
      '--aspect-ratio': ratio ? ratio : null,
      ...sx,
    }}
    {...props}
  />
))

export default useFitAnything
