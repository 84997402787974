import { memo, forwardRef } from 'react'
import { Flex } from 'theme-ui'

import { Cloudinary } from '@cloudinary/base'
import { AdvancedImage } from '@cloudinary/react'
import { inset } from '@monobits/core'
import cls from 'classnames'

import { getCloudinaryProps, plugins } from './utils'
import * as tr from './transformations'

const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.GATSBY_CLOUDINARY_CLOUD_NAME,
    url: { secure: !!(process.env.NODE_ENV === 'production') },
  },
})

/**
 * https://cloudinary.com/documentation/sdks/js/frontend-frameworks/index.html
 * @example <CloudImage sx={{ width: 300 }} ratio="contain" fit="cover" url={image?.filename} medias={medias} />
 */
const CloudImage = forwardRef(
  ({ url, name, asset, ratio, fit = 'cover', options, modifiers, sx, className, img = {}, ...props }, ref) => {
    let cloudProps, source

    if (asset) {
      source = cld.image(asset.public_id)
    } else if (name) {
      source = cld.image(name)
    } else {
      cloudProps = getCloudinaryProps(url)
      source = cld.image(cloudProps.filename)
    }

    if (modifiers) {
      modifiers(source, tr)
    } else {
      source.addTransformation(tr.optimize()).addTransformation(tr.optimizeScale())
    }

    const image = (
      <AdvancedImage
        cldImg={source}
        plugins={plugins({ ...options })}
        width={asset?.width}
        height={asset?.height}
        alt={asset?.alt}
        {...img}
      />
    )

    return asset || ratio ? (
      <Flex
        ref={ref}
        {...props}
        className={cls(className, 'image')}
        data-aspect-ratio
        sx={{
          '--aspect-ratio': ratio || asset?.aspect_ratio,
          position: 'relative',
          userSelect: 'none',

          img: {
            size: '100%',
            objectFit: fit,
            userSelect: 'none',
            position: 'absolute',
            ...inset(0),
          },
          sx,
        }}
      >
        {image}
      </Flex>
    ) : (
      <Flex
        ref={ref}
        className={cls(className, 'image')}
        sx={{ userSelect: 'none', img: { objectFit: fit, userSelect: 'none' }, ...sx }}
        {...props}
      >
        {image}
      </Flex>
    )
  }
)

export default memo(CloudImage)
