import { useUpdateEffect, useConditionalEffect, useClickOutside } from '@react-hookz/web'
import { useLockBodyScroll } from 'react-use'
import { useTimeline, willChangeEvents } from '@monobits/gsap'

import useMenuState from './useMenuState'

const BASE = {
  duration: 1.25,
  ease: 'expo.inOut',
}

const useMenu = (tween = () => {}, { location, defaults = BASE, timescale: [a, b] = [1, 1] }) => {
  const [open, setOpen] = useMenuState()

  const [timeline, element] = useTimeline(({ gsap, node, $ }) => {
    const tl = gsap.timeline({
      defaults,
      paused: true,
      ...willChangeEvents(node),
    })

    return tween(node, tl)
  })

  useLockBodyScroll(open || false)
  useUpdateEffect(() => setOpen(false), [location])
  useClickOutside(element, () => open != null && setOpen(false))

  useConditionalEffect(
    () => {
      if (open) timeline.timeScale(a).play()
      if (!open) timeline.timeScale(b).reverse()
    },
    [open],
    [timeline],
    undefined,
    useUpdateEffect
  )

  return [element.ref, open, setOpen]
}

export default useMenu
