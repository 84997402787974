import { makeFluids } from '@monobits/scales'

const fluid = makeFluids(
  {
    'padding-s': ['10px', '20px', false],
    'padding-x': ['20px', '30px'],
    'padding-m': ['24px', '48px'],
    'padding-l': ['30px', '68px'],
    'padding-xl': ['40px', '80px'],
    'padding-xxl': ['40px', '150px'],
    'padding-xxxl': ['100px', '300px'],
  },
  ['375px', '2500px']
)

export const rootScaleStyles = {
  ...fluid.properties,
  '--stroke-x': '1px',
  '--stroke-s': '1px',
  '--stroke-m': '2px',
}

export default {
  space: fluid.scale,
  borderWidths: [0, 1, 2],
  sizes: {
    icon: '3.5rem',
  },
  zIndices: {
    below: -1,
    default: 1,
    nav: 10,
    popup: 20,
    panel: 30,
    modal: 40,
    scrollbar: 50,
  },
}
