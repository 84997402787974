export default {
  icon: { size: { '--stroke-absolute': (t) => t.borderWidths[1] + 'px' } },
  layout: {
    section: {
      cards: {
        mt: 5,
        mb: 6,
        display: 'grid',

        gridTemplateColumns: ['1fr', '1fr 1fr', 'repeat(auto-fill, minmax(22em, 1fr))'],

        gridColumnGap: 1,
        gridRowGap: 3,
      },
      index: {
        mt: 5,
        mb: 'var(--toolbar-padding)',

        width: '100%',
        display: 'grid',

        gridTemplateColumns: ['1fr', '1fr 1fr', 'repeat(auto-fill, minmax(22em, 1fr))'],

        gridColumnGap: 1,
        gridRowGap: 3,
      },
      list: {
        pt: 5,
        pb: ['var(--toolbar-padding)', null, 5],

        minHeight: 'calc(100vh - (var(--padding-x) * 2))',

        fontSize: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '>div': {
          display: 'inline-flex',

          flexWrap: [null, null, 'wrap'],
          flexDirection: ['column', null, 'row'],
          alignItems: 'center',
          justifyContent: 'center',
          columnGap: '0.85em',
          rowGap: ['0.75em', null, '0.45em'],
          px: [null, '0.5em'],
        },
      },
      text: {
        pt: [6, 'calc(var(--padding-xxl) + var(--padding-x))'],
        pb: 5,
        // px: 1,

        width: '100%',
        pr: [null, null, 'calc(var(--running-img-width) + var(--padding-x))'],
      },
    },
  },
  button: {
    styles: {
      fontSize: 1,
      textTransform: 'uppercase',

      '&::before': {
        borderRadius: '200px',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'var(--border-color)',
      },
    },
    variants: {
      primary: {
        '--text': (t) => t.colors.background,
        '--text-hover': (t) => t.colors.text,
        '--bg': (t) => t.colors.primary,
        '--bg-hover': (t) => t.colors.background,
        '--border-color': (t) => t.colors.text,

        '--b': (t) => t.colors.background,
      },
      secondary: {
        '--text': (t) => t.colors.text,
        '--text-hover': (t) => t.colors.background,
        '--bg': (t) => t.colors.background,
        '--bg-hover': (t) => t.colors.text,
        '--border-color': (t) => t.colors.text,

        '--b': 'transparent',
      },
      primaryFixed: {
        '--text': (t) => t.colors.background,
        '--text-hover': (t) => t.colors.background,
        '--bg': (t) => t.colors.text,
        '--bg-hover': (t) => t.colors.text,
        '--border-color': (t) => t.colors.text,

        '--b': (t) => t.colors.background,
      },
      secondaryFixed: {
        '--text': (t) => t.colors.text,
        '--text-hover': (t) => t.colors.text,
        '--bg': (t) => t.colors.background,
        '--bg-hover': (t) => t.colors.background,
        '--border-color': (t) => t.colors.text,

        '--b': 'transparent',
      },
    },
    sizes: {
      sm: { p: '0.5em' },
      md: { p: ['1em', '0.7em 0.8em'] },
      lg: { p: '0.65em' },
      __default: { variant: 'button.sizes.md' },
    },
    layout: {
      __default: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
        justifyContent: 'flex-start',
      },
      withicon: {
        '>div': {
          display: 'inline-flex',
          alignItems: 'center',
        },
        'svg, .icon': {
          my: '-1em',
          width: 'unset',
          height: 'var(--icon-height, 1em)',

          mr: '0.55em',
        },
      },
    },
  },
  colorswitch: {
    variants: {
      bullet: {
        '--size': 'var(--icon-root-width, 0.65rem)',
        borderRadius: 'unset',
        transform: 'rotate(45deg)',
        mt: '0.1rem',
      },
    },
    types: {
      inverted: {
        '--size': 'calc(var(--icon-root-width, 0.65rem) + (var(--stroke-x) * 2))',
        border: 'var(--stroke-x) solid var(--bg)',
        bg: 'var(--color)',
      },
    },
  },
}
