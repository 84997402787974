import { useRef, useMemo } from 'react'
import { Box } from 'theme-ui'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { useLocomotiveScroll } from '@monobits/locomotive-scroll'
import { useTween } from '@monobits/gsap'
import { gsap } from 'gsap'
import { CloudImage } from '@boiler/cloudinary'

import { Underline } from '../atoms'
import Placeholder from './Placeholder'

const increments = 50
const $ = (obj) => obj?.current

const InlineProject = ({ title, image: { url, alt }, index = 1, ...props }) => {
  const breakpoint = useBreakpointIndex()
  const { scroll } = useLocomotiveScroll()

  const even = index % 2
  const dynamic = breakpoint === 3 || breakpoint === 2

  let sizes = useRef()
  const [tween, element] = useTween(({ node }) =>
    gsap.effects.fade(node, {
      from: { rotate: even ? gsap.utils.random(2, 6) : gsap.utils.random(-6, -2) },
      to: { duration: 0.3, delay: 0.05, ease: 'expoOut', overwrite: 'auto', paused: true },
    })
  )

  const handleEnter = (e) => {
    const { isMobile, isTablet, instance, windowHeight } = scroll.scroll

    const imgWidth = gsap.getProperty($(element), 'width')
    const imgHeight = gsap.getProperty($(element), 'height')
    const padding = gsap.getProperty($(sizes), 'width')

    const offset = padding * 2
    const windowTop = instance.scroll.y
    const windowBottom = instance.scroll.y + windowHeight

    const scene = {
      x: [offset, window.innerWidth - imgWidth - offset],
      y: [windowTop + offset, windowBottom - imgHeight - offset],
    }

    const xPos = gsap.utils.random(...scene.x, (scene.x[0] - scene.x[1]) / increments)
    const yPos = gsap.utils.random(...scene.y, (scene.y[0] - scene.y[1]) / increments)

    if (!isMobile || !isTablet) {
      gsap.set($(element), { x: xPos, y: yPos })
      tween?.play()
    }
  }

  const handleLeave = () => tween?.reverse()

  const image = useMemo(
    () => (
      <CloudImage
        url={url}
        alt={alt}
        options={{ lazyload: !!(index + 1 < 3) ? false : null, placeholder: false }}
        fit="contain"
      />
    ),
    [url] // eslint-disable-line
  )

  return (
    <>
      <div ref={element.ref} sx={{ variant: 'running.image', left: 0, right: 'revert' }}>
        {dynamic && (
          <>
            <span ref={sizes} sx={{ variant: 'running.image.pad' }} />
            {url ? image : <Placeholder />}
          </>
        )}
      </div>
      <Box
        sx={{
          display: 'inline-block',
          textAlign: 'center',
          lineHeight: 1,
          '@media (hover: hover)': {
            '[data-hovering=true] & > span': { opacity: 0.35 },
            '> span:hover': { opacity: '1' },
            span: { transition: 'opacity var(--ui-duration) var(--ui-ease)' },
          },
        }}
        onPointerEnter={dynamic ? handleEnter : null}
        onPointerLeave={dynamic ? handleLeave : null}
        {...props}
      >
        <Underline as="span" variant="list" sx={{ pb: 0, '--spacing': '88%' }} inverted>
          {title}
        </Underline>
      </Box>
    </>
  )
}

export default InlineProject
