import { ColorSwitch } from '@monobits/components'
import { useModes } from '@monobits/color-transitions'
import { InvertedMode, Icons } from '../../atoms'
import useMenu from './useMenu'

const MenuComponent = ({ primary, secondary, copyrights, location, ...props }) => {
  const context = useModes()

  const [ref, open, setOpen] = useMenu(handleTween, {
    defaults: { duration: 1.25, ease: 'expo.inOut' },
    timescale: [1, 1.75],
    location,
  })

  return (
    <section ref={ref} sx={{ variant: 'menu', transformOrigin: 'bottom right' }} aria-hidden={!!!open} {...props}>
      <InvertedMode shouldTween={!!open} ssx={{ display: 'flex' }} observe={false}>
        <div sx={{ variant: 'menu.container' }}>
          <div sx={{ variant: 'menu.head' }}>
            <ColorSwitch context={context} sx={{ variant: 'colorswitch.types.inverted' }} />
            <button sx={{ variant: 'menu.close' }} onClick={() => setOpen(false)}>
              <Icons name="cross" />
            </button>
          </div>

          <div sx={{ variant: 'menu.body' }}>
            <nav sx={{ variant: 'menu.nav' }}>{primary}</nav>
            <div>
              <nav sx={{ variant: 'menu.nav.secondary' }}>{secondary}</nav>
              <h4 sx={{ variant: 'menu.copyrights' }} children={copyrights} />
            </div>
          </div>
        </div>
      </InvertedMode>
    </section>
  )
}

function handleTween(node, tl) {
  return tl.set(node, { visibility: 'visible', skewX: -1.75, x: 12 }).to(node, { '--clip-path': '0%', x: 0, skewX: 0 })
}

export default MenuComponent
