import { useThemeUI } from 'theme-ui'
import { useLocomotiveScroll } from '@monobits/locomotive-scroll'
import { useConditionalEffect } from '@react-hookz/web'

import { useTransitions } from './Transitions'

const ScrollBridge = () => {
  const { scroll } = useLocomotiveScroll()
  const { nativeDuration } = useThemeUI().theme?.transitions?.defaults ?? {}

  const [variants] = useTransitions()

  useConditionalEffect(
    () => {
      window.loco = { delay: variants.exit?.transition?.duration * 1000 || nativeDuration, scroll }
    },
    [variants],
    [scroll]
  )

  return null
}

export default ScrollBridge
