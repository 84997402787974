import { useRef, useState, useEffect } from 'react'
import { getWindow } from 'ssr-window'
import { gsap } from 'gsap'
import { useEventListener, useDebouncedCallback, useResizeObserver, useMediaQuery } from '@react-hookz/web'

const $ = (obj) => obj?.current
const OPTIONS = {
  lerp: 0.5,
  threshold: 100,
  debounce: 1000,
}

const utils = {
  lerp: (value, delta) => (value === 1 || value === 0 ? 1.0 : 1.0 - Math.pow(1.0 - value / 10, delta)),
  distance: (x1, y1, x2, y2) => Math.hypot(x2 - x1, y2 - y1),
}

const window = getWindow()

const useMousePos = (
  [container, element],
  { options: overwrites = {}, axis: [x, y] = [true, true], setter, defaultHover, onDistance = () => {} } = {}
) => {
  const [hover, setHover] = useState(defaultHover)
  const [rect, setRect] = useState()

  let pos = useRef(null)
  let mouse = useRef(null)
  let cached = useRef(null)

  const options = { ...OPTIONS, ...overwrites }
  const isTouch = useMediaQuery('(hover: none)', true)

  useEffect(() => {
    if (!isTouch) {
      pos.current = { x: 0, y: 0 }
      mouse.current = { ...$(pos) }
      cached.current = { ...$(mouse) }

      if (element) {
        gsap.set(element, {
          xPercent: x ? -50 : null,
          yPercent: y ? -50 : null,
        })
      }

      const _set = (value, unit = 'px') =>
        element ? (setter ? setter({ gsap, ref: element, value }) : gsap.quickSetter(element, value, unit)) : null

      const getMouseDistance = () => utils.distance($(pos).x, $(pos).y, $(cached).x, $(cached).y)

      gsap.ticker.add(() => {
        const amt = utils.lerp(options.lerp, gsap.ticker.deltaRatio())

        $(pos).x += ($(mouse).x - $(pos).x) * amt
        $(pos).y += ($(mouse).y - $(pos).y) * amt

        if (element && x) _set('x')($(pos).x)
        if (element && y) _set('y')($(pos).y)

        let distance = getMouseDistance()
        if (distance > options.threshold) {
          onDistance({
            distance,
            pos: $(pos),
            mouse: $(mouse),
            cached: $(cached),
          })

          cached.current = { ...$(pos) }
        }
      })
    }
  }, [rect, isTouch])

  useEventListener(
    window,
    'mousemove',
    (event) => {
      const prevent = defaultHover ? true : hover
      mouse.current.x = prevent ? event.clientX : $(mouse).x
      mouse.current.y = prevent ? event.clientY : $(mouse).y
    },
    { passive: true }
  )

  const debounced = useDebouncedCallback((e) => setRect(e.contentRect), [setRect], options.debounce)
  useResizeObserver(container, debounced)

  return {
    pos: $(pos),
    mouse: $(mouse),
    cached: $(cached),
    hover: [hover, setHover],
    is: { touch: isTouch, mouse: !isTouch },
  }
}

export default useMousePos
