import styled from '@emotion/styled'
import { position } from 'polished'

import { css, Flex } from 'theme-ui'

const x = {
  outerHeight: 'calc(var(--header-height) + (var(--padding-x) * 2))',
  padding: 'var(--padding-x, 0px)',
  speed: 'var(--speed, 0.45s)',
  ease: 'var(--ease, ease)',
}

const stickyStyles = (use) =>
  use && {
    ...position('fixed', 0, 0, null, 0),
    zIndex: 'popup',
  }
const shyStyles = (use) =>
  use && {
    '[data-direction="down"] &': {
      '--translate': `calc(${x.outerHeight} * -1)`,
    },
    '[data-scroll-position="top"] &,[data-scroll-position="bottom"] &': {
      '--translate': '0.0001px',
    },
    [StyledContainer]: {
      transform: 'translate3d(0, var(--translate, 0.0001px), 0)',
      transition: `transform ${x.speed} ${x.ease}`,
      willChange: 'transform',
    },
  }

export const StyledHeader = styled(Flex)(({ sticky, shy }) =>
  css({
    width: '100%',

    ...stickyStyles(sticky),
    ...shyStyles(sticky && shy),
  })
)

export const StyledContainer = styled(Flex)(
  css({
    width: `calc(100% - (${x.padding} * 2))`,
    position: 'relative',
    top: x.padding,
    left: x.padding,
  })
)
