import { useRef, useState, createContext } from 'react'
import { getWindow } from 'ssr-window'
import { useThemeUI, Box } from 'theme-ui'
import { motion, AnimatePresence } from 'framer-motion'
import { useSessionStorageValue, useUpdateEffect } from '@react-hookz/web'
import { ColorModeTransition } from '@monobits/color-transitions'
import { LocomotiveScrollProvider } from '@monobits/locomotive-scroll'
import { Sizes } from '@monobits/components'

import useStoryblok from './useStoryblok'

import { useTransitions } from './Transitions'
import ScrollBridge from './ScrollBridge'
import ScrollTriggerProxy from './ScrollTriggerProxy'
import Header from '../components/Header'
import Menu from '../components/Menu'
import Intro from '../components/Intro'
import VideoScrub from '../components/VideoScrub'

const window = getWindow()

export const StoryblokContext = createContext()

const Layout = ({ children, pageContext, location }) => {
  const ref = useRef(null)

  const {
    config: { locomotive },
  } = useThemeUI().theme ?? {}
  const { settings, locales = {} } = useStoryblok(pageContext, location)
  const { slug } = pageContext?.story ?? {}

  const [variants, variantKeys] = useTransitions()

  const [rendered, setRendered, removeRendered] = useSessionStorageValue('app-rendered', false, {
    handleStorageEvent: true,
    isolated: false,
  })

  window.onunload = () => removeRendered()

  const home = !!(slug === 'home')

  const [isHome, setIsHome] = useState(home)

  useUpdateEffect(() => {
    setTimeout(() => setIsHome(home), variants.exit.transition.duration * 1000)
  }, [home])

  return (
    <>
      <StoryblokContext.Provider
        value={{
          pathname: location.pathname,
          settings,
          locales,
          rendered: [rendered, setRendered],
        }}
      >
        <Sizes id="sizes" callbacks={location} />

        <LocomotiveScrollProvider containerRef={ref} location={location} options={locomotive}>
          <ScrollTriggerProxy location={location} />
          <ColorModeTransition />
          <ScrollBridge />

          <Menu location={location} />

          {home && !rendered && <Intro color="text" delay={300} onComplete={() => setRendered(true)} />}

          <Header show={{ switcher: !home, homeLink: !home }} sx={{ color: home ? 'light' : null }} />

          <Box ref={ref} data-scroll-container>
            {isHome && <VideoScrub locales={locales} />}
            <AnimatePresence exitBeforeEnter>
              <motion.main key={location.pathname} variants={variants} {...variantKeys}>
                {children}
              </motion.main>
            </AnimatePresence>
          </Box>
        </LocomotiveScrollProvider>
      </StoryblokContext.Provider>
    </>
  )
}

export default Layout
