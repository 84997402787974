import { fluidRange } from '@monobits/scales'

export default {
  __default: {
    display: 'flex',
    flexDirection: 'column',

    '--height': 'calc(100vh - (var(--padding-x) * 2) - var(--padding-xl))',
    minHeight: '100vh',
  },
  container: {
    pl: [null, null, '0.5rem'],
    mt: 4,
    display: 'grid',

    gridTemplateColumns: ['1fr', null, '5rem 1fr 1fr'],
    gridTemplateRows: [null, null, '1fr'],
    gridGap: [3, null, fluidRange('40px', '250px', ['1400px', '2500px'])],
  },
  images: {
    overflow: 'hidden',
    width: ['100%', null, '100%'],
    height: ['calc(var(--vh, 1vh) * 85)', null, 'fit-content'],

    '.swiper': {
      height: 'calc((var(--vh, 1vh) * 85) - var(--padding-x))',
    },

    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: 1,
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'flex-start',
    justifyContent: 'center',

    textAlign: ['center', null, 'left'],

    '&[data-need-room=true]': {
      transform: 'none!important',
    },
    '&[data-need-room=false]': {
      height: [null, null, 'var(--height)'],
    },
    '&[data-contained=true]': {
      alignItems: 'center',
    },
  },
  nav: {
    position: 'relative',
    height: 'fit-content',
    minHeight: 'var(--height)',

    'img, video': {
      userSelect: 'none',
    },

    '>div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem',

      '>aside': {
        opacity: 0,
        height: '1.2rem',
        position: 'absolute',
        left: '-0.5rem',
        pointerEvents: 'none',

        zIndex: 'nav',

        transition: 'opacity 1s ease',
      },
    },
  },
}
