import { GatsbySeo } from 'gatsby-plugin-next-seo'

const makeHrefLang = (alternates) =>
  alternates
    ? Object.entries(alternates).map(([key, value]) => ({
        hrefLang: key,
        href: value,
      }))
    : null

const SEO = ({ blok, settings, pageContext, ...props }) => {
  const { story, realPath, lang: { iso = '', alternates = {} } = {} } = pageContext ?? {}

  const meta = blok?.meta
  const { title, description, image: blok_image, meta_image: blok_meta_image } = blok ?? {}
  const { meta_title, meta_description, meta_image, sep } = settings ?? {}

  const siteName = meta_title || null
  const schema_image = blok_image && Array.isArray(blok_image) ? blok_image[1]?.filename : blok_image?.filename
  const image = meta?.og_image || blok_meta_image?.filename || schema_image || meta_image?.filename || null

  const options = {
    language: iso,
    languageAlternates: makeHrefLang(alternates),
    canonical: story?.full_slug === 'home' ? '/' : story?.full_slug,

    title: meta?.title || title || meta_title || null,
    description: meta?.description || description || meta_description || null,
    titleTemplate: siteName ? `%s ${sep || '|'} ${siteName}` : null,

    openGraph: {
      url: realPath,
      locale: iso + '_CA',
      site_name: siteName,
      title: meta?.og_title || null,
      description: meta?.og_description || null,
      images: image ? [{ url: image }] : undefined,
    },
  }

  return <GatsbySeo key={settings?._uid} {...options} {...props} />
}

export default SEO
