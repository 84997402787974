import { useData } from '../hooks'
import { Bloks } from '../bloks'

const ContactList = () => {
  const { settings } = useData()

  if (!settings) return null

  const data = settings.contact_nav

  return (
    <div sx={{ fontSize: 4 }}>
      {!!data.length &&
        data.map((item, i) => (
          <span
            key={item._uid}
            sx={{ display: 'inline-flex', alignItems: 'flex-start', mr: i + 1 < data.length && '0.4em' }}
          >
            <Bloks blok={item} variant="title" />
            <span sx={{ mt: '-0.08em' }}>{i + 1 < data.length && ','}</span>
          </span>
        ))}
    </div>
  )
}

export default ContactList
