import { RecoilRoot } from 'recoil'

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
  if (typeof ResizeObserver === 'undefined') {
    let { install } = await import('resize-observer')
    install()
  }
}

export const wrapRootElement = ({ element }) => {
  return <RecoilRoot>{element}</RecoilRoot>
}

/**
 * Prevent scrollTop on routeChange until page transition is triggered
 */
export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if ('loco' in window) {
    const { scroll, delay, behavior } =
      {
        scroll: window.loco.scroll,
        delay: window.loco.delay || 500,
        behavior: 'auto',
      } ?? {}

    const smooth = window.innerWidth >= scroll.tablet.breakpoint
    const scrollTop = () => smooth && scroll.scrollTo(0, { duration: 0, disableLerp: true })

    if (location.action === 'PUSH') {
      window.setTimeout(() => {
        window.scrollTo({ top: 0, left: 0, behavior })
        scrollTop()
      }, delay)
    } else {
      const [left, top] = getSavedScrollPosition(location)
      window.setTimeout(() => {
        window.scrollTo({ top: top || 0, left: left || 0, behavior })
        scrollTop()
      }, delay)
    }
    return false
  }
}
