import { useRef, useState, memo } from 'react'
import { useMediaQuery, useConditionalEffect } from '@react-hookz/web'
import { useMousePos } from '@boiler/utils'
import { useTween } from '@monobits/gsap'
import loadable from '@loadable/component'
import gsap from 'gsap'

import { Bloks } from '../../bloks'
import { Underline, Link } from '../../atoms'
import { useData } from '../../hooks'
import ScrollDown from '../ScrollDown'

import { useTransitions } from '../../layouts/Transitions'
import useFitAnything, { Aspect, Target } from './useFitAnything'

const Landscape = loadable(() => import('./Landscape'))
const Portrait = loadable(() => import('./Portrait'))

const placement = ['bottom', 'top', 'top', 'bottom', 'top']

const Common = ({ children, sx, ...props }) => {
  const { intro_links = {}, intro_tracking = {}, contact_nav = {}, copyrights = {} } = useData()?.settings

  const { refs } = useFitAnything({ fit: 'cover' })
  const isLandscape = useMediaQuery('(orientation: landscape)')
  const isTouch = useMediaQuery('(hover: none)')
  const [variants] = useTransitions()
  const [hover, setHover] = useState(false)

  let placeholder = useRef(null)
  let visible = useRef(false)

  const [tween, cursor] = useTween(({ gsap, node, $ }) =>
    gsap.fromTo(
      node,
      { autoAlpha: 0, scale: 0 },
      { autoAlpha: 0.5, scale: 1, duration: 0.3, ease: 'circ.out', overwrite: 'auto', paused: true }
    )
  )

  const { is } = useMousePos([refs.target, cursor.current], { options: { lerp: 0.5 }, defaultHover: true })

  useConditionalEffect(
    () => {
      if (hover) tween.play()
      if (!hover) tween.reverse()
    },
    [hover],
    [!is.touch, tween]
  )

  useConditionalEffect(
    () => {
      visible.current = true
      gsap.fromTo(
        refs.container.current,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: variants.exit.transition.duration, delay: 0.2 }
      )

      document.querySelectorAll('a').forEach((el) => {
        !!!el?.target &&
          el.addEventListener('click', () => {
            gsap.to(refs.container.current, { autoAlpha: 0, duration: variants.exit.transition.duration })
          })
      })
    },
    undefined,
    [refs.container.current != null, !!!visible.current]
  )

  let video = null
  if (isLandscape != null) {
    video = !!isLandscape ? <Landscape /> : <Portrait />
  }

  const secondary =
    contact_nav &&
    contact_nav.map((item) => (
      <Bloks blok={item} key={item._uid} variant="caption" color="light" inverted sx={{ width: 'fit-content' }} />
    ))
  const line = copyrights && copyrights.map((item) => <Bloks blok={item} key={item._uid} variant="caption" />)

  const links = {
    ui: intro_links?.map(({ title, link, params, _uid }) => ({ link, params, title, id: _uid })),
    tracking: intro_tracking?.map(({ link, params, _uid }) => ({ link, params, id: _uid })),
  }

  const bindHover = {
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false),
  }

  return (
    <>
      <section
        ref={refs.container}
        data-scroll
        data-scroll-sticky
        data-scroll-target="#placeholder"
        sx={{ variant: 'scrub', visibility: 'hidden', opacity: 0, ...sx }}
        {...props}
      >
        <Aspect
          ref={refs.aspect}
          sx={{
            '@media (orientation: landscape)': { '--aspect-ratio': '16/9' },
            '@media (orientation: portrait)': { '--aspect-ratio': '9/16' },
          }}
        />
        <div ref={cursor.ref} sx={{ variant: 'scrub.cursor' }} />

        {video}

        <Target ref={refs.target} as="aside" sx={{ variant: 'scrub.zones' }}>
          {!isTouch && <ScrollDown data-scroll-down />}

          <div className="nav">
            {links?.tracking?.map((node, i) => (
              <Link
                key={node.id}
                link={node.link}
                params={node?.params}
                data-index={i + 1}
                className="hitbox"
                {...bindHover}
              />
            ))}
          </div>
        </Target>

        {links?.ui?.map((node, i) => (
          <Link
            link={node.link}
            params={node?.params}
            key={node.id}
            data-trigger={i + 1}
            data-position={placement[i]}
            {...bindHover}
          >
            <Underline sx={{ color: 'light' }} color="light" inverted={!!isLandscape} variant="menu">
              {node.title}
            </Underline>
          </Link>
        ))}

        <nav data-trigger="contact" sx={{ variant: 'scrub.contact' }} {...bindHover}>
          {secondary}
        </nav>
        <div sx={{ variant: 'scrub.copy' }}>{line}</div>

        {isTouch && <ScrollDown.Mobile data-scroll-down />}
      </section>

      <div id="placeholder" ref={placeholder} sx={{ minHeight: '1000vh' }} />
    </>
  )
}

const types = { down: ['from', 'to'], up: ['to', 'from'] }
const actions = (initial = {}) => ({
  from: { autoAlpha: 0, y: '0.5em', filter: 'blur(4px)', duration: 0.75, ...initial },
  to: { autoAlpha: 1, y: '0em', filter: 'blur(0px)', duration: 2, ...initial },
})
export const triggers = {
  trigger: '#placeholder',
  start: 'top top',
  end: 'bottom bottom',
  scrub: 0.4,
  markers: false,
}
export const attributes = {
  sx: { variant: 'scrub.media' },
  playsInline: true,
  'webkit-playsinline': 'true',
  preload: 'auto',
  muted: 'muted',
}
export const tween = (node, { invert = false, extend = {}, direction, gsap, initial = {} }) => {
  const forward = direction === 1 ? 'down' : 'up'
  const backward = forward === 'down' ? 'up' : 'down'
  const options = !invert ? types[forward] : types[backward]

  const sets = actions(initial)

  return gsap.fromTo(node, { ...sets[options[0]] }, { ...sets[options[1]], ...extend })
}
export const select = (id = 0) => `[data-trigger="${id}"]`.toString()

export default memo(Common)
