const x = {
  height: 'var(--header-height, 100px)',
  padding: 'var(--padding-x)',
}

export default {
  container: {
    '--height': x.height,
    '--speed': '0.65s',
    '--ease': 'ease',
  },
  cell: {
    height: x.height,
    placeItems: 'flex-start flex-end',
    position: 'absolute',
    top: 0,
  },
  logo: {
    variant: 'header.cell',
    left: 0,

    '@media (hover: none)': {
      '.-mouse': { display: 'none' },
    },
    '@media (hover: hover)': {
      '.-touch': { display: 'none' },
    },
    '@media (max-width: 42.95em)': {
      '.-mouse': { display: 'none' },
      '.-touch': { display: 'flex' },
    },

    svg: { width: 'unset', height: '100%' },
  },
  nav: {
    variant: 'header.cell',
    right: 0,

    width: 'var(--panel-nav-width)',
    gridTemplateColumns: '1fr var(--panel-icon-size)',
    gridTemplateRows: '1fr',
    gridColumnGap: 4,
  },
}
