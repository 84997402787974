import { gsap } from 'gsap'
import { TextPlugin } from 'gsap/TextPlugin'

gsap.registerPlugin(TextPlugin)

gsap.registerEffect({
  name: 'userSelect',
  effect: (targets, config = false) => {
    if (config.value !== true) {
      return gsap.set(targets, { '--user-select': config.value })
    } else {
      return gsap.set(targets, { clearProps: '--user-select' })
    }
  },
  extendTimeline: true,
})

gsap.registerEffect({
  name: 'fade',
  effect: (targets, config) => {
    return gsap.fromTo(
      targets,
      {
        scale: 0.98,
        rotate: 2,
        autoAlpha: 0,
        ...config.from,
      },
      {
        scale: 1,
        rotate: 0,
        autoAlpha: 1,
        ...config.to,
      }
    )
  },
  defaults: {
    to: {
      duration: 0.2,
      ease: 'expoOut',
      paused: true,
    },
  },
  extendTimeline: true,
})
