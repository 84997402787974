import { Header as Molecule } from '@boiler/molecules'
import { Heading, ColorSwitch } from '@monobits/components'
import { useModes } from '@monobits/color-transitions'

import { useData } from '../hooks'
import useMenuState from './Menu/useMenuState'

const Header = ({ show = { switcher: true, homeLink: true }, ...props }) => {
  const {
    settings,
    locales: { locales = {} },
  } = useData()

  const context = useModes()

  const [, setOpen] = useMenuState()

  if (!settings || locales.length === 0) return null

  const {
    color_switcher: whitelist = ['light', 'dark'], //
  } = settings ?? {}

  const homePath = '/'

  return (
    <Molecule
      logo={{ text: <Heading children="NTHN" /> }}
      homePath={homePath}
      sx={{ '[data-logos]': { pointerEvents: !show.homeLink ? 'none' : null } }}
      {...props}
    >
      <ColorSwitch context={context} sx={{ visibility: !show.switcher ? 'hidden' : null }} whitelist={whitelist} />
      <Heading as="button" onClick={() => setOpen(true)} aria-label={locales['menu']} children={locales['menu']} />
    </Molecule>
  )
}

export default Header
