import { snakeCaseReducer } from '../'

export const reduceSettings = (array, lang) =>
  array.reduce((acc, obj, i) => {
    const languages = lang ? Object.keys(lang?.alternates || []) : []
    const iso = languages[i]
    return {
      ...acc,
      ...{ [lang ? (iso === lang?.default ? 'default' : iso) : 'default']: obj.content },
    }
  }, {})

export const getSettings = async ({ pageContext, lang, Storyblok, config, setter }) => {
  if (pageContext) {
    const languages = lang && lang?.alternates ? Object.keys(lang?.alternates) : ['default']

    // Settings (all languages)
    const promises = []
    for (const iso of languages) {
      await Storyblok.get(`cdn/stories/settings`, {
        language: lang && iso === lang?.default ? null : iso,
        version: 'draft',
        ...snakeCaseReducer(config),
      }).then(({ data }) => promises.push(data.story))
    }
    Promise.all(promises).then((results) => results.length && setter(reduceSettings(results, lang)))
  }
}

export const returnSettings = (settings, iso) => (settings ? settings[iso] : null)
