import { useMemo, useRef, useState } from 'react'
import { useConditionalEffect, useUpdateEffect, useMediaQuery } from '@react-hookz/web'
import { useLockBodyScroll } from 'react-use'
import { useThemeUI } from 'theme-ui'
import { useLocomotiveScroll } from '@monobits/locomotive-scroll'
import { useTween } from '@monobits/gsap'

import Lottie from 'lottie-react'
import { flatten } from 'lottie-colorify'
import desktop from '../../../assets/intro_desktop.json'
import mobile from '../../../assets/intro_mobile.json'

const Intro = ({ delay = 0, color, config, onComplete = () => {}, ...props }) => {
  let lottieRef = useRef()
  const { scroll } = useLocomotiveScroll()
  const { rawColors } = useThemeUI().theme
  const isMobile = useMediaQuery('(hover: none)', true)

  const [locked, setLocked] = useState(true)
  const json = isMobile ? mobile : desktop
  const hex = color in rawColors ? rawColors[color] : color

  const [tween, container] = useTween(({ gsap, node }) =>
    gsap
      .timeline({
        paused: true,
        onComplete: onTweenComplete,
      })
      .to(node, { '--clip-path': '100%', skewY: -4, duration: 1.25, ease: 'expo.inOut' })
      .set(node, { visibility: 'hidden' })
  )

  useConditionalEffect(
    () => {
      scroll.stop()
    },
    undefined,
    [scroll, !!locked]
  )
  useConditionalEffect(
    () => {
      scroll.update()
      scroll.start()
    },
    undefined,
    [!!!locked],
    undefined,
    useUpdateEffect
  )
  useLockBodyScroll(locked)

  const onLottieComplete = () => tween.play()
  const onTweenComplete = () => {
    setLocked(false)
    onComplete && onComplete()
  }

  const onLoaded = () => setTimeout(() => lottieRef.current.play(), delay)

  const lottie = useMemo(
    () => (
      <Lottie
        lottieRef={lottieRef}
        animationData={color ? flatten(hex, json) : json}
        onDOMLoaded={onLoaded}
        onComplete={onLottieComplete}
        autoplay={false}
        loop={false}
      />
    ),
    [scroll, tween, json] // eslint-disable-line
  )

  return (
    locked && (
      <section {...props} ref={container.ref} sx={{ variant: 'intro' }}>
        {lottie}
      </section>
    )
  )
}

export default Intro
