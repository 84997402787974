import { checkWebPSupport } from 'supports-webp-sync'

import { ImageTransformation } from '@cloudinary/base'
import { format, quality } from '@cloudinary/base/actions/delivery'
import { auto as autoFormat, svg } from '@cloudinary/base/qualifiers/format'
import { auto as autoQuality } from '@cloudinary/base/qualifiers/quality'
import { crop, scale } from '@cloudinary/base/actions/resize'
import { autoGravity } from '@cloudinary/base/qualifiers/gravity'

import { vectorize, pixelate } from '@cloudinary/base/actions/effect'

export const optimize = (q = autoQuality(), f = autoFormat()) =>
  new ImageTransformation().delivery(format(f)).delivery(quality(q))

export const autoWebp = (q = autoQuality()) =>
  new ImageTransformation().delivery(format(checkWebPSupport() ? 'webp' : 'jpg')).delivery(quality(q))

export const svgOnly = (f = autoFormat()) => new ImageTransformation().delivery(format(svg()))

export const squareAutoFocus = (width) =>
  new ImageTransformation().resize(crop().width(width).height(width).gravity(autoGravity()))

export const optimizeScale = (max = 2200) => new ImageTransformation().resize(scale().width(max))

export const dominantScale = (max = 2200) => new ImageTransformation().resize(scale().width(max).height(max))

export const placehoderVector = (f = autoFormat()) =>
  new ImageTransformation().delivery(format('svg')).addTransformation(optimizeScale(500)).effect(vectorize())

export const placehoderPixelate = (f = autoFormat()) =>
  new ImageTransformation().delivery(format(f)).addTransformation(optimizeScale(800)).effect(pixelate(10))
