import { useEffect } from 'react'
import { useThemeUI } from 'theme-ui'
import { useSetRecoilState, useRecoilValue, atom } from 'recoil'
import { motion, AnimatePresence } from 'framer-motion'
import { omit } from 'lodash'

export const layoutTransition = atom({
  key: 'layoutTransition',
  default: 'fade',
})

const Transitions = ({ children, transition: name, ...props }) => {
  const { transitions } = useThemeUI().theme ?? {}
  const { variantKeys, defaultTransition } = transitions

  const key = name ? name : defaultTransition
  const variants = transitions[key]

  useTransitionsEffect((set) => set(key))

  return name ? (
    <AnimatePresence exitBeforeEnter>
      <motion.section variants={variants} {...variantKeys} {...props}>
        {children}
      </motion.section>
    </AnimatePresence>
  ) : (
    children
  )
}

export const useTransitions = () => {
  const transition = useRecoilValue(layoutTransition)
  const { transitions } = useThemeUI().theme ?? {}
  const { variantKeys } = transitions

  const obj = transitions[transition]
  const variants = {
    initial: transitions.reset,
    enter: {
      opacity: 1,
      transition: obj.enter.transition,
      transitionEnd: { pointerEvents: 'all' },
    },
    ...omit(obj, ['enter', 'initial']),
  }

  return [variants, variantKeys, transition]
}

export const useTransitionsEffect = (effect = () => {}, deps = []) => {
  const setTransition = useSetRecoilState(layoutTransition)

  useEffect(() => effect(setTransition), deps) // eslint-disable-line
}

export default Transitions
