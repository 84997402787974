import { useThemeUI } from 'theme-ui'
import { transparentize } from 'polished'
import { Text } from '@monobits/components'

const Underline = ({ children, color = 'text', inverted = false, variant = 'title', sx, ...props }) => {
  const { alpha } = useThemeUI().theme?.underline ?? {}

  const getColor = (t) => (color in t.rawColors ? t.rawColors[color] : color)

  const logic = {
    forward: {
      '--to': (t) => transparentize(alpha, getColor(t)),
      '--from': (t) => getColor(t),
    },
    backward: {
      '--to': (t) => getColor(t),
      '--from': (t) => transparentize(alpha, getColor(t)),
    },
  }[inverted ? 'backward' : 'forward']

  return (
    <Text
      sx={{
        variant: 'underline',
        '&': { variant: `text.${variant}` },
        ...logic,
        ...sx,
      }}
      {...props}
      rte={false}
    >
      {children}
    </Text>
  )
}

export default Underline
