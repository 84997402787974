import ReactObfuscate from 'react-obfuscate'
import parsePhoneNumber from 'libphonenumber-js'
import { pick } from 'lodash'

import { Underline } from '../atoms'

const Link = ({ data, pre, ...props }) => (
  <Underline {...props}>
    {pre && <span>{pre}</span>}
    <ReactObfuscate {...data} />
  </Underline>
)

const Obfuscate = ({ blok, ...props }) => {
  const { email, custom_link, text } = blok
  const link = custom_link || email

  const content = {
    email: link,
    children: text || link,
    headers: {
      ...pick(blok, ['subject', 'body']),
      cc: blok?.cc.list.map((node) => node.text).join() || null,
    },
    target: '_blank',
  }

  return <Link data={content} {...props} />
}

const Phone = ({ blok, ...props }) => {
  const { phone, custom_link, text } = blok
  const link = custom_link || phone

  const phoneNumber = parsePhoneNumber(link, 'CA')

  const content = {
    tel: phoneNumber?.number || link,
    children: text || phoneNumber.formatNational(),
  }

  return <Link data={content} pre="[ T ]:&nbsp;" {...props} />
}

Obfuscate.Email = Obfuscate
Obfuscate.Phone = Phone

export default Obfuscate
