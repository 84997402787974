import { Section } from '@monobits/components'
import SbEditable from 'storyblok-react'

import { Rte } from '../atoms'

const RunningText = ({ blok, children, lang, parentId, ...props }) => {
  const { content, _uid } = blok ?? {}

  return (
    <SbEditable content={blok} key={_uid}>
      <Section variant="text" data-handle={_uid} {...props}>
        <Rte content={content} variant="title" />
      </Section>
    </SbEditable>
  )
}

export default RunningText
