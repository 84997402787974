const degrees = ['0.001deg', '45deg', '90deg', '135deg', '180deg', '225deg', '270deg', '315deg', '360deg']

const fontVariant = 'text.caption'

export default {
  styles: {
    variant: fontVariant,
  },
  collapsible: {
    variant: 'list.styles',

    '::before,::after': {
      display: 'none',
    },

    display: 'grid',
    gridRowGap: '0.75em',

    'article > div': {
      mt: 0,
      mb: '0.75em',
    },

    head: {
      width: 'fit-content',
      position: 'relative',

      gridTemplateColumns: '1fr auto',
      gridColumnGap: '0.5em',
      gridRowGap: 0,
    },

    text: {
      variant: fontVariant,
      width: 'fit-content',
      pb: 0,
      gridColumn: '1/2',
    },
  },
  icon: {
    height: '100%',

    display: 'inline-flex',
    alignItems: 'center',

    aside: {
      my: '-1em',
      width: 'unset',
      height: 'var(--icon-height)',

      '--rotation': '0.001deg',

      '[data-selected=true] &': {
        '--rotation': degrees[2],
      },
      '[aria-expanded=true] &': {
        '--rotation': degrees[3],
      },

      transform: 'rotate(var(--rotation))',
      transition: 'transform var(--speed) ease',
    },
  },
}
