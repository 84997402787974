const pad = 1
const flexed = {
  display: 'flex',
  flexDirection: 'column',
}

export default {
  __default: {
    display: 'flex',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 'panel',

    visibility: 'hidden',
    clipPath: 'inset(0 0 0 var(--clip-path, 100%))',
    '--clip-path': '100%',

    nav: {
      display: 'flex',
      flexDirection: 'column',

      alignItems: 'flex-start',
    },
  },

  container: {
    p: pad,
    width: ['100vw', null, 'var(--panel-nav-width)'],
    position: 'relative',

    ...flexed,
    alignItems: 'flex-end',

    boxSizing: ['border-box', null, 'content-box'],

    bg: 'background',
    color: 'text',
  },

  head: {
    variant: 'header.nav',
    display: 'grid',
    position: 'relative',
    minHeight: 'var(--panel-icon-size)',
    mb: 1,
  },

  body: {
    ...flexed,
    width: '100%',
    justifyContent: 'space-between',
    height: '100%',
    gap: 2,
  },

  nav: {
    gap: '0.85em',
    secondary: { gap: '0.5em', display: ['none!important', null, 'flex!important'] },
    '@media (hover: hover)': {
      '[data-hovering=true] & > a': { opacity: 0.35 },
      '> a:hover': { opacity: '1' },
    },
  },

  copyrights: {
    position: 'absolute',
    bottom: pad,
    right: pad,
    left: [pad, null, 'unset'],
  },

  close: {
    size: 'var(--panel-icon-size)',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    svg: {
      width: '100%',
      height: 'auto',
    },
  },
}
