import { buildFonts, fontlist } from '@monobits/fonts'
import { makeFluids } from '@monobits/scales'

const fluid = makeFluids(
  {
    'size-caption': ['16px', '22px'],
    'size-emphasized': ['24px', '50px'],
    'size-subtitle': ['60px', '110px'],
    'size-title': ['37px', '120px'],
    'size-menu': ['52px', '110px', false],
    'size-list': ['46px', '110px'],
  },
  ['375px', '2500px']
)

export const scales = {
  fontSizes: fluid.scale,
  fontWeights: {
    body: 300,
    heading: 300,
    emphasized: 300,
  },
  lineHeights: {
    body: 1.26,
    heading: 1.05,
    emphasized: 1.1,
  },
}

export const rootFontStyles = {
  fontSize: 1,
  fontFamily: 'roslindale',
  lineHeight: 'body',
  fontWeight: 'body',

  '--rte': '1.35em',
  '.-rte + .-rte': { mt: 'var(--rte)' },

  ...fluid.properties,
}

const common = (type = 'body', uppercase = false) => ({
  textTransform: uppercase ? 'uppercase' : null,
  fontFamily: type,
  fontWeight: type,
  lineHeight: type,
  crops: 'roslindale',
})

const roslindale = {
  family: 'Roslindale',
  fallbacks: 'georgia, serif',
  crop: [5, 8.25],
}

export default buildFonts([fontlist(['roslindale'], { roslindale }), scales], {
  fonts: {
    heading: 'inherit',
    body: 'inherit',
    emphasized: 'inherit',
    monospace: 'Menlo, monospace',
  },
  variants: {
    key: 'text',
    heading: { variant: 'text.caption' },
    __default: { variant: 'text.title' },
    __cropped: {
      list: { fontSize: 5, ...common('heading') },
      title: { fontSize: 4, ...common('heading') },
      menu: { fontSize: 'var(--size-menu)', ...common('heading') },
      subtitle: { fontSize: 3, ...common('body') },
      emphasized: { fontSize: 2, ...common('emphasized') },
      caption: { fontSize: 1, ...common('body', true) },
      text: { fontSize: 1, ...common('body') },
      running: { fontSize: 1, ...common('body') },
    },
  },
})
