import { forwardRef } from 'react'
import { Box } from 'theme-ui'
import { Icons } from '../atoms'

const Placeholder = forwardRef(({ ratio, sx, ...props }, ref) => (
  <Box ref={ref} {...props}>
    <div data-aspect-ratio sx={{ '--aspect-ratio': ratio ? ratio : null, bg: 'background', ...sx }}>
      <Icons preserveAspectRatio="none" name="placeholder" />
    </div>
  </Box>
))

export default Placeholder
