import { inset } from '@monobits/core'

export default {
  media: { size: '100%', objectFit: 'cover' },
  ratio: {
    width: '100%',

    position: 'absolute',
    top: 0,
    left: 0,
  },
  zones: {
    size: '100%',

    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 'nav',

    '.nav': { size: '100%', willChange: 'transform' },

    '.hitbox': {
      display: 'flex',

      position: 'absolute',
      '@media (orientation: landscape)': {
        '&[data-index="1"]': {
          left: '39.5%',
          bottom: '40.65%',

          width: '20.5%',
          height: '10%',
        },
        '&[data-index="2"]': {
          left: '39.5%',
          bottom: '31%',

          width: '12%',
          height: '9%',
        },
        '&[data-index="3"]': {
          left: '39.5%',
          bottom: '21%',

          width: '18%',
          height: '9.5%',

          transform: 'skew(5deg) rotate(-2deg)',
        },
        '&[data-index="4"]': {
          left: '39.5%',
          bottom: '10.5%',

          width: '13.5%',
          height: '10%',
          transform: 'translateX(1.25rem) skew(12deg) rotate(-6deg)',
        },
      },
      '@media (orientation: portrait)': {
        '&[data-index="1"]': {
          left: '20%',
          bottom: '42%',

          width: '53%',
          height: '8%',
        },
        '&[data-index="2"]': {
          left: '19%',
          bottom: '34.5%',

          width: '29%',
          height: '7%',
        },
        '&[data-index="3"]': {
          left: '19%',
          bottom: '26.5%',

          width: '44%',
          height: '7%',

          transform: 'skew(4deg)',
        },
        '&[data-index="4"]': {
          left: '23.5%',
          bottom: '18.5%',

          width: '33%',
          height: '7%',
        },
      },
    },
  },
  noise: {
    position: 'absolute',
    ...inset(0),

    opacity: 0.15,
    mixBlendMode: 'soft-light',
  },
  contact: {
    display: 'flex',
    flexDirection: 'column',
    gap: ['0.5em', null, '0.25em'],

    position: 'absolute',
    bottom: ['calc((var(--padding-x) * 2) + 1ex)', null, 1],
    left: 1,
    zIndex: 'nav',
    color: 'light',

    alignItems: ['center', null, 'flex-start'],
  },
  copy: {
    position: 'absolute',
    bottom: 1,
    right: 1,
    zIndex: 'nav',
    color: 'light',
  },
  cursor: {
    size: '3.5rem',
    bg: 'light',

    borderRadius: '50%',

    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 'popup',

    pointerEvents: 'none',
    visibility: 'hidden',
    opacity: 0,

    transform: 'scale(0)',
    transformOrigin: 'center center',
  },
  __default: {
    width: '100vw',

    '@media (hover: hover)': { height: '100vh' },
    '@media (hover: none)': { height: 'calc(var(--vh-x, 1vh) * 100)' },

    position: 'fixed',
    zIndex: 'default',
    ...inset(0),

    '>[data-trigger]': {
      opacity: 0,
      visibility: 'hidden',
      transform: 'translateY(0.5em)',
      filter: 'blur(4px)',

      position: 'absolute',
      zIndex: 'nav',

      textTransform: 'uppercase',

      '@media (orientation: landscape)': {
        '&[data-position=bottom]': { bottom: '20%', left: '8%' },
        '&[data-position=top]': { top: '20%', right: '8%' },
      },
      '@media (orientation: portrait)': {
        '&[data-trigger]': { left: '50%' },
        '&[data-trigger="1"]': { bottom: '10%' },
        '&[data-trigger="2"]': { top: '10%' },
        '&[data-trigger="3"]': { bottom: '10%' },
        '&[data-trigger="4"]': { bottom: '18%' },
        '&[data-trigger="5"]': { bottom: '52%' },
      },
    },
  },
}
