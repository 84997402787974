import { useMemo } from 'react'
import { useLocomotiveScroll } from '@monobits/locomotive-scroll'
import { useTween, useRefs } from '@monobits/gsap'
import { gsap } from 'gsap'
import { CloudImage } from '@boiler/cloudinary'

const $ = (obj) => obj.current

const RunningImage = ({ children, href }) => {
  const { scroll } = useLocomotiveScroll()

  let refs = useRefs(['parent', 'sizes'])
  const [tween, element] = useTween(({ node }) => gsap.effects.fade(node))

  const handleEnter = (e) => {
    const { isMobile, isTablet, instance, windowHeight } = scroll.scroll

    const y = e.clientY + instance.scroll.y
    const imgHeight = gsap.getProperty($(element), 'height')
    const padding = gsap.getProperty($(refs.sizes), 'width')

    const offset = padding * 2
    const imgBottom = y + imgHeight + offset
    const windowBottom = instance.scroll.y + windowHeight

    let top
    if (imgBottom > windowBottom) {
      top = windowBottom - imgHeight - offset
    } else {
      top = y
    }

    if (isMobile || isTablet) {
      gsap.set($(element), {
        position: 'fixed',
        top: '50%',
        left: '50%',
        xPercent: -50,
        yPercent: -50,
        width: '50%',
        maxHeight: window.innerHeight - offset + 'px',
      })
    } else {
      gsap.set($(element), { top })
    }

    tween.play()
    gsap.effects.userSelect('html', { value: 'none' })
  }

  const handleLeave = () => {
    tween.reverse()
    gsap.effects.userSelect('html', { value: true })
  }

  const image = useMemo(() => <CloudImage as="span" url={href} fit="contain" alt={children.toString()} />, []) // eslint-disable-line

  return (
    <>
      <span ref={element.ref} sx={{ variant: 'running.image' }}>
        <span ref={refs.sizes} sx={{ variant: 'running.image.pad' }} />
        {image}
      </span>
      <span
        ref={refs.parent}
        sx={{ variant: 'running.image.text' }}
        onPointerEnter={handleEnter}
        onPointerLeave={handleLeave}
        data-underline
      >
        {children}
      </span>
    </>
  )
}

export default RunningImage
