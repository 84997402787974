import { useState, useRef } from 'react'
import { useResizeObserver, useConditionalEffect } from '@react-hookz/web'
import { useLocomotiveScroll } from '@monobits/locomotive-scroll'
import { useStateRef } from '@monobits/gsap'
import { gsap } from 'gsap'

const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

const useBetterSticky = (target = '', id = '') => {
  const [values, setValues] = useState()
  const [container, containerRef] = useStateRef()
  const content = container?.firstChild
  const parentNode = container?.parentNode

  let distance = useRef(0)
  let max = useRef(0)
  let top = useRef(null)

  const { scroll } = useLocomotiveScroll()

  useResizeObserver(content || container, () => {
    const child = content?.clientHeight
    const parent = container?.clientHeight
    const body = parentNode?.clientHeight

    const needRoom = body < child

    const contained = child <= parent
    const overflow = contained ? 0 : child - parent

    setValues({ contained, overflow, needRoom })
    max.current = overflow

    if (scroll) scroll.update()
  })

  const handleScroll = (args) => {
    const {
      speed = 0,
      direction,
      scroll: { y },
    } = args

    const sticky = !!Object.values(args.currentElements).filter((el) => id.replace('#', '') === el.el.id).length
    const velocity = Math.abs(speed)

    if (!!y && sticky) {
      if (direction === 'down') distance.current = clamp(distance.current + velocity, 0, max.current)
      if (direction === 'up') distance.current = clamp(distance.current - velocity, 0, max.current)

      // position seems to perform better than transform
      gsap.set(content, { top: -distance.current, position: 'relative' })
    }
  }

  useConditionalEffect(
    () => {
      const { contained, needRoom } = values
      if (top.current == null) {
        top.current = container?.getBoundingClientRect().top
      }

      if (!contained && !needRoom) scroll.on('scroll', handleScroll)
      return () => scroll.off('scroll', handleScroll)
    },
    [values?.overflow],
    [scroll, values != null]
  )

  return {
    ref: containerRef,
    top: top.current,
    attributes: {
      'data-scroll': true,
      'data-scroll-sticky': true,
      'data-scroll-target': target,
      'data-scroll-offset': [-top.current, top.current],
      'data-contained': !!values?.contained || true,
      'data-need-room': !!values?.needRoom || false,
    },
  }
}

export default useBetterSticky
