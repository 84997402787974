import { transitions, easeInOut } from 'polished'

export default {
  alpha: 1,
  __default: {
    '--stroke': 'var(--stroke-x)',
    pb: 'clamp(4px, 0.08em, 100px)',

    '--spacing': '100%',

    textDecoration: 'none',
    backgroundImage: 'linear-gradient(var(--from), var(--from)), linear-gradient(var(--to), var(--to))',
    backgroundPosition: '100% var(--spacing), 0 var(--spacing)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% var(--stroke), 0 var(--stroke)',
    ...transitions(['background-size', 'opacity'], `var(--ui-duration) ${easeInOut('cubic')}`),

    '@media (hover: hover)': {
      '&:hover': {
        backgroundSize: '0 var(--stroke), 100% var(--stroke)',
      },
    },
  },
}
