import { useState } from 'react'
import { Bloks } from '../../bloks'
import { useData } from '../../hooks'
import Component from './Menu.component'

const Menu = ({ location, ...props }) => {
  const { settings } = useData()
  const [hovering, setHovering] = useState(false)

  if (!settings) return null

  const { navigation, contact_nav, copyrights } = settings

  const primary =
    navigation &&
    navigation.map((item) => (
      <Bloks
        blok={item}
        key={item._uid}
        inverted
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      />
    ))
  const secondary =
    contact_nav && contact_nav.map((item) => <Bloks blok={item} key={item._uid} variant="caption" inverted />)
  const line = copyrights && copyrights.map((item) => <Bloks blok={item} key={item._uid} variant="caption" />)

  return (
    <Component
      {...props}
      data-hovering={!!hovering}
      primary={primary}
      secondary={secondary}
      copyrights={line}
      location={location}
    />
  )
}

export default Menu
