import { memo, forwardRef } from 'react'
import { Box } from 'theme-ui'

import CloudImage from './CloudImage'
import CloudVideo from './CloudVideo'

const CloudAssets = forwardRef(
  (
    { assets, image: imageProps = () => {}, video: videoProps = () => {}, onClick = () => {}, icons: Icons, ...props },
    ref
  ) => {
    return assets.map(({ component, image, asset, width, height, _uid }, i) => {
      if (component === 'image') {
        return (
          <CloudImage
            ref={ref}
            key={i + _uid}
            url={image.filename}
            alt={image?.alt}
            ratio={width && height ? width / height : null}
            onClick={(e) => onClick(_uid, i, e)}
            {...imageProps(_uid, i)}
            {...props}
          />
        )
      }
      if (component === 'video') {
        return (
          <CloudVideo
            ref={ref}
            key={i + _uid}
            url={asset.filename}
            ratio={width && height ? width / height : null}
            onClick={(e) => onClick(_uid, i, e)}
            {...videoProps(_uid, i)}
            {...props}
          />
        )
      }

      if (Icons && component === 'placeholder') {
        return (
          <Box ref={ref} key={i + _uid} onClick={(e) => onClick(_uid, i, e)} {...props}>
            <div
              data-aspect-ratio
              sx={{
                '--aspect-ratio': width && height ? width / height : null,
                [width > height ? 'width' : 'height']: '100%',

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icons preserveAspectRatio="none" name="placeholder" />
            </div>
          </Box>
        )
      }

      return null
    })
  }
)

export default memo(CloudAssets)
