export default {
  useLocalStorage: true,
  useColorSchemeMediaQuery: false,

  initialColorModeName: 'dark',

  useStickyHeader: true,
  useShyHeader: false,

  colorTransitions: {
    duration: 0.5,
    ease: 'power1.inOut',
  },

  locomotive: {
    smooth: true,
    multiplier: 0.6,
    lerp: 0.075,

    scrollFromAnywhere: true,
    tablet: { breakpoint: 992 },
    extends: {
      scrub: false,
      rootPercent: 4,
      scrollDuration: 800,
    },
  },
}
