import Placeholder from './Placeholder'
import GlobalComponent from './GlobalComponent'

import * as X from '../components'
import * as Y from '../atoms'

const Components = {
  global: GlobalComponent,
  'use-global': GlobalComponent,
  'nav-item': X.NavItem,
  'text-item': X.TextItem,
  'copyrights-mention': X.Copyrights,
  'email-link': X.Obfuscate.Email,
  'phone-link': X.Obfuscate.Phone,
  'multiline-link': Y.MultilineLink,
  'running-text': X.RunningText,
  'contact-list': X.ContactList,
  heading: X.Heading,
  navigation: Y.Links,
  collapsibles: X.Collapsibles,
  rte: X.RteItem,
}

const DynamicComponent = ({ blok, source, ...props }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return <Component blok={blok} key={blok._uid} {...props} />
  }

  return blok.component ? <Placeholder componentName={blok.component} /> : null
}

export default DynamicComponent
