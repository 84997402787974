import { merge } from 'theme-ui'
import { preset } from '@monobits/preset-base'
import { locomotive } from '@monobits/styles'
import { inset } from '@monobits/core'
import { easeInOut } from 'polished'
import { omit } from 'lodash'

import config from './config'
import styles from './styles'
import colors from './colors'
import overrides from './overrides'
import transitions from './transitions'
import fonts, { rootFontStyles } from './fonts'
import scales, { rootScaleStyles } from './scales'
import * as variants from './variants'

import './gsap'

export default merge(omit(preset, ['colors']), {
  config,
  colors,

  breakpoints: ['43em', '62em', '82em'],

  styles: {
    root: {
      ...styles,
      ...locomotive,
      ...rootFontStyles,
      ...rootScaleStyles,

      '--icon-root-width': '0.6rem',
      '--icon-height': '1.2em',
      '--icon-width': '1.25em',
      '--cards-columns': ['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
      '--main-columns': ['repeat(4, 1fr)', null, 'repeat(12, 1fr)'],
      '--main-gap': ['var(--padding-s)', null, 'var(--padding-x)'],
      '--toolbar-padding': ['calc(var(--padding-xxxl) * 1.75)', null, 'var(--padding-xxxl)'],

      '--running-img-width': '18rem',

      '--header-height': '1rem',

      '--panel-nav-width': '30vw',
      '--panel-icon-size': '2.5rem',

      '--ui-duration': '0.45s',
      '--ui-ease': 'ease',

      body: { bg: 'background', '.c-scrollbar': { zIndex: 'scrollbar' } },

      '&.has-scroll-smooth body': {
        position: 'fixed',
        ...inset(0),
      },
    },
  },

  transitions,

  motion: {
    collapsible: {
      transition: {
        duration: 0.35,
        ease: 'easeInOutExpo',
      },
      bezier: easeInOut('expo'),
    },
  },

  ...fonts,
  ...scales,
  ...overrides,
  ...variants,
})
