import PropTypes from 'prop-types'

import { forwardRef } from 'react'
import { Link } from 'gatsby'
import { Flex, Grid, useThemeUI } from 'theme-ui'
import { Icon } from '@monobits/components'

import { StyledHeader, StyledContainer } from './Header.styled'

/**
 * This is the Header component
 */
const Header = forwardRef(
  ({ children, logo: { text: TextLogo, assets: Assets = [] } = {}, homePath, sx, ...props }, ref) => {
    const { useStickyHeader = false, useShyHeader = false } = useThemeUI(null).theme?.config

    const linked = homePath && { as: Link, to: homePath }

    return (
      <StyledHeader
        ref={ref}
        as="header"
        sx={{ variant: 'header.container', ...sx }}
        shy={useShyHeader}
        sticky={useStickyHeader}
        {...props}
      >
        <StyledContainer>
          <Flex
            {...linked}
            sx={{ variant: 'header.logo' }}
            aria-label="Logo"
            data-logos={!!Assets.length < 1}
            {...props}
          >
            {!!Assets.length ? (
              <>
                <Icon className="-mouse" icon={Assets[0]} variant="scale" />
                {Assets.length < 1 && <Icon className="-touch" icon={Assets[1]} variant="scale" />}
              </>
            ) : (
              TextLogo
            )}
          </Flex>
          <Grid as="nav" sx={{ variant: 'header.nav' }}>
            {children}
          </Grid>
        </StyledContainer>
      </StyledHeader>
    )
  }
)

Header.propTypes = {
  /** Content or components that will display in the navigation */
  children: PropTypes.any,
  /** Takes a SVGR Component */
  logo: PropTypes.object,
  /** The url path of the homepage. Allows for localized paths */
  homePath: PropTypes.string,
  /** Makes the header sticky */
  sticky: PropTypes.bool,
  /** Makes the header show/hide on scroll up/down */
  shy: PropTypes.bool,
  /** Add a margin as a placeholder for the height of the header */
  spacer: PropTypes.bool,
}

export default Header
