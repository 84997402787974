export default {
  sticker: {
    'html.has-scroll-smooth &': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100vw',
      height: '10000vh',
      visibility: 'hidden',
    },
    'html:not(.has-scroll-smooth) &': { display: 'none' },
  },
  fixed: {
    position: 'fixed',
    left: 1,
    'html.has-scroll-smooth &': { top: 'calc(100vh - var(--padding-x))' },
    'html:not(.has-scroll-smooth) &': { bottom: 1 },
    zIndex: 'nav',

    '@media (max-width: 61.95em)': {
      right: 1,
    },

    nav: {
      display: 'grid',
      gridAutoFlow: 'column',
      gridGap: '0.75em',

      '@media (max-width: 61.95em)': {
        width: '100%',
        gridTemplateColumns: 'repeat(2, 1fr)',
        '[data-view]': { gridColumn: '1/-1', gridRow: '2/3' },
      },

      'html.has-scroll-smooth &': {
        position: 'absolute',
        transform: 'translateY(-100%)',
        pt: '1px',
      },
    },
  },

  card: {
    width: '100%',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',

    container: {
      pt: 1,
      gridTemplateColumns: '1fr auto',
    },
    foot: {
      display: 'flex',
      '.index': {
        mr: [6, 5],
      },
    },
  },
}
