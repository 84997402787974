import { Grid } from 'theme-ui'
import { Heading } from '@monobits/components'
import { CloudImage } from '@boiler/cloudinary'
import { multiLink } from '@boiler/utils'

const MediaCard = ({ children, title, date = {}, link, image, source, even, sx, ...props }) => {
  const publishDate = new Intl.DateTimeFormat(date?.locale, date?.format).format(
    new Date(date?.date?.replace(/-/g, '/').split(' ')[0])
  )

  const to = { ...multiLink(link), rel: 'noopener' }

  return (
    <article sx={{ variant: 'card', ...sx }} data-even={!!even} {...props}>
      <CloudImage {...image} sx={{ variant: 'card.image' }} {...to} />

      <div sx={{ variant: 'card.container' }}>
        <Grid sx={{ variant: 'card.head' }}>
          <Heading as="h2" className="title">
            {title}
          </Heading>
          <Heading as="h4" className="date">
            {publishDate}
          </Heading>
          <Heading as="h3" className="source">
            {source}
          </Heading>
        </Grid>

        <Heading as="p" variant="text" sx={{ variant: 'card.body' }}>
          {children}
        </Heading>

        <Heading {...to} sx={{ display: 'inline-block' }}>
          View Article
        </Heading>
      </div>
    </article>
  )
}

export default MediaCard
