import SbEditable from 'storyblok-react'
import { Section, Heading as Component } from '@monobits/components'

import { NewlineText } from '../atoms'

const Heading = ({ blok, children, ...props }) => {
  const { title, _uid } = blok ?? {}

  return (
    <SbEditable content={blok} key={_uid}>
      <Section variant="heading" data-handle={_uid} data-scroll data-fade>
        <span className="anchor" data-anchor={_uid} />
        <Component variant="title" {...props}>
          {children || <NewlineText text={title} id={_uid} />}
        </Component>
      </Section>
    </SbEditable>
  )
}

export default Heading
