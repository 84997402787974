import {
  render,
  NODE_HEADING,
  NODE_PARAGRAPH,
  NODE_LI,
  MARK_UNDERLINE,
  MARK_LINK,
} from 'storyblok-rich-text-react-renderer'
import { Text, Underlined } from '@monobits/components'

import Link from './Link'
import { Bloks } from '../bloks'
import RunningImage from '../components/RunningImage'

const Rte = ({ children: nodes, head, content, sx, variant = 'text', ...props }) => {
  const cleanup = content?.content?.filter((i) => 'content' in i)
  const validContent = !!content && !!cleanup?.length

  return validContent || nodes ? (
    <div sx={{ variant: 'rte', ...sx }} {...props}>
      {head}
      {validContent &&
        render(content, {
          nodeResolvers: {
            [NODE_HEADING]: (children, { level, ...props }) => {
              const options = [
                { variant: 'title', as: 'h2' },
                { variant: 'subtitle', as: 'h3' },
                { variant: 'caption', as: 'h4' },
              ][level - 1]
              return <Text {...options} children={children} {...props} />
            },
            [NODE_PARAGRAPH]: (children, props) => <Text variant={variant} children={children} {...props} />,
            [NODE_LI]: (children, props) => (
              <Text variant={variant} as="li" rte={false} cropped={false} children={children} {...props} />
            ),
          },
          markResolvers: {
            [MARK_UNDERLINE]: (children) => <Underlined children={children} />,
            [MARK_LINK]: (children, props) => {
              const type = props.linktype === 'asset' ? 'asset' : 'link'

              return {
                asset: <RunningImage {...props}>{children}</RunningImage>,
                link: (
                  <Link className="link" link={props}>
                    {children}
                  </Link>
                ),
              }[type]
            },
          },
          defaultBlokResolver: (name, props) => (
            <Bloks
              blok={{ component: name, ...props }}
              sx={{ fontSize: 'inherit', textTransform: 'inherit' }}
              inverted={false}
            />
          ),
        })}
      {nodes}
    </div>
  ) : null
}

export default Rte
