import { multiLink } from '@boiler/utils'
import { Underline } from '../atoms'

const NavItem = ({ blok, ...props }) => {
  const { link, params, text, locale } = blok ?? {}

  return (
    <Underline variant="menu" {...props} {...multiLink(link, params)}>
      {text || locale}
    </Underline>
  )
}

export default NavItem
