import { parseToRgb } from 'polished'
import { makeHighlight } from '@monobits/colors'

const opacity = 0.15
const toRgbString = (obj) => Object.values(parseToRgb(obj)).join()

const colors = {
  light: '#F1E8DB',
  dark: '#232720',
}

export default {
  text: colors.light,
  background: colors.dark,
  primary: colors.light,
  highlight: makeHighlight(colors.light, opacity),
  cursor: toRgbString(colors.light),

  ...colors,
  modes: {
    light: {
      text: colors.dark,
      background: colors.light,
      primary: colors.dark,
      highlight: makeHighlight(colors.dark, opacity),
      cursor: toRgbString(colors.dark),
    },
  },
}
