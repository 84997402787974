import { useRef, useState, memo } from 'react'
import { useLocomotiveScroll } from '@monobits/locomotive-scroll'
import { useConditionalEffect, useDebouncedEffect, useResizeObserver } from '@react-hookz/web'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

/**
 * Locomotive Scroll + Scroll Trigger,
 * proxy magic to make those two work together.
 *
 * @example
 * <LocomotiveScrollProvider {...settings}>
 *  <ScrollTriggerProxy />
 *  {children}
 * </LocomotiveScrollProvider>
 */

const scrollAxis = (args, value, scroll, axis = 'y') =>
  args.length ? scroll.scrollTo(value, 0, 0) : scroll.scroll.instance.scroll[axis]

const ScrollTriggerProxy = ({ location }) => {
  const { scroll } = useLocomotiveScroll()
  const [rect, setRect] = useState({})
  const [containerSizes, setContainerSizes] = useState([])

  let init = useRef(false)
  const watch = [rect?.width, rect?.height]

  useResizeObserver(scroll?.el, (e) => setRect(e.contentRect))
  useDebouncedEffect(() => setContainerSizes(watch), watch, 150)

  useConditionalEffect(
    () => {
      const element = scroll?.el

      scroll.on('scroll', ScrollTrigger?.update)

      ScrollTrigger?.scrollerProxy(element, {
        scrollTop(value) {
          return scrollAxis(arguments, value, scroll, 'y')
        },
        scrollLeft(value) {
          return scrollAxis(arguments, value, scroll, 'x')
        },
        getBoundingClientRect() {
          return {
            top: 0,
            left: 0,
            width: window.innerWidth,
            height: window.innerHeight,
          }
        },
        pinType: element.style.transform ? 'transform' : 'fixed',
      })

      init.current = true
    },
    [containerSizes],
    [scroll, !!containerSizes.length]
  )

  useConditionalEffect(
    () => {
      ScrollTrigger?.addEventListener('refresh', () => scroll?.update())
      ScrollTrigger?.refresh()

      return () => {
        ScrollTrigger?.removeEventListener('refresh', scroll?.update())
      }
    },
    [location.pathname],
    [scroll, !!init.current]
  )

  return null
}

export default memo(ScrollTriggerProxy)
