import { useRef, useMemo } from 'react'
import { useThemeUI } from 'theme-ui'

import Lottie from 'lottie-react'
import { flatten } from 'lottie-colorify'
import desktop from '../../assets/scroll_desktop.json'
import mobile from '../../assets/scroll_mobile.json'

const Desktop = ({ color = 'dark', sx, ...props }) => {
  let ref = useRef(null)
  const { rawColors } = useThemeUI().theme

  const hex = color in rawColors ? rawColors[color] : color

  const lottie = useMemo(
    () => (
      <Lottie lottieRef={ref} animationData={color ? flatten(hex, desktop) : desktop} autoplay={true} loop={true} />
    ),
    [hex, color]
  )

  return (
    <div sx={{ variant: 'intro.lottie.desktop', ...sx }} {...props}>
      {lottie}
    </div>
  )
}

export const Mobile = ({ color = 'dark', sx, ...props }) => {
  let ref = useRef(null)
  const { rawColors } = useThemeUI().theme

  const hex = color in rawColors ? rawColors[color] : color

  const lottie = useMemo(
    () => <Lottie lottieRef={ref} animationData={color ? flatten(hex, mobile) : mobile} autoplay={true} loop={true} />,
    [hex, color]
  )

  return (
    <div sx={{ variant: 'intro.lottie.mobile', ...sx }} {...props}>
      {lottie}
    </div>
  )
}

Desktop.Mobile = Mobile

export default Desktop
