const gap = 'var(--padding-x)'

export default {
  __default: {
    '--cell-width': `calc(100% + ${gap})`,
    height: 'calc(var(--vh, 1vh) * 65)',

    'img, video, [data-aspect-ratio]': {
      pr: gap,
    },
  },
  bar: {
    mx: 2,
    width: 'calc(100% - (var(--padding-x) * 2))',
    height: '1px',
    bg: 'text',
    transform: 'scaleX(var(--initial-scale, 0.001))',
    transformOrigin: 'left',
    opacity: 1,
    willChange: 'transform',
  },
}
