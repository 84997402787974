export default {
  image: {
    __default: {
      display: 'flex',
      width: 'var(--running-img-width)',

      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1,

      visibility: 'hidden',
      pointerEvents: 'none',
      userSelect: 'none',

      '--aspect-ratio': '1/1',

      '>div:not(.image)': {
        width: '100%',

        '[data-aspect-ratio]': {
          display: 'flex',
          width: '98%',
        },
      },
    },
    pad: {
      visibility: 'hidden',
      position: 'absolute',
      size: 'var(--padding-x)',
    },
    text: {
      fontSize: 'inherit',
      cursor: 'e-resize',
    },
  },
}
