export default {
  __default: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 'modal',

    p: 1,
    bg: 'background',

    '@media (hover: hover)': {
      height: '100vh',
    },
    '@media (hover: none)': {
      bottom: 0,
    },

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    visibility: 'visible',
    clipPath: 'inset(var(--clip-path, 0%) 0 0 0)',
    '--clip-path': '0%',

    transform: 'skew(0deg)',
    transformOrigin: 'bottom right',

    '>div': {
      size: '100%',
      maxHeight: '32rem',
    },

    'svg [font-size]': {
      fill: 'text',
    },
  },
  lottie: {
    desktop: {
      position: 'absolute',
      width: '10%',

      top: '25%',
      right: '28%',

      zIndex: '999999',
    },
    mobile: {
      bg: 'light',

      position: 'absolute',
      width: '4rem',

      left: 1,
      bottom: 1,
      zIndex: '999999',

      transform: 'none!important',

      borderRadius: '1rem',
    },
  },
}
