import { useRef } from 'react'
import { useConditionalEffect } from '@react-hookz/web'
import { useLocomotiveScroll } from '@monobits/locomotive-scroll'
import { gsap } from 'gsap'

import { Icons } from '../atoms'

const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

const StickyIndicator = ({ children, icon, target, offset = [0, 0], variant = 'product.nav', sx, ...props }) => {
  const { scroll } = useLocomotiveScroll()

  let ref = useRef(null)
  const container = ref.current
  const indicator = container?.firstChild

  const handleScroll = (args) => {
    const { y } = args.scroll ?? {}

    const element = Object.values(args.currentElements).filter((el) => !!(el.el.dataset?.target === target))[0]

    if (!!y && element?.progress != null) {
      const b = gsap.getProperty(indicator, 'height')
      const c = gsap.getProperty(container, 'height')

      const tween = clamp(c * element?.progress, b / 2, c - b / 2)

      gsap.set(indicator || '[data-indicator]', { y: tween, opacity: 0.45 })
    }
  }

  useConditionalEffect(
    () => {
      scroll.update()

      gsap.set(indicator || '[data-indicator]', {
        xPercent: -100,
        yPercent: -50,
      })

      scroll.on('scroll', handleScroll)
      return () => scroll.off('scroll', handleScroll)
    },
    undefined,
    [scroll, ref != null]
  )

  return (
    <nav
      data-scroll
      data-target={target}
      data-scroll-target={target}
      data-scroll-offset={offset}
      data-scroll-sticky
      sx={{ variant: 'product.nav', ...sx }}
      {...props}
    >
      <div ref={ref}>
        <aside data-indicator>{icon ? icon : <Icons name="arrow-right" />}</aside>
        {children}
      </div>
    </nav>
  )
}

export default StickyIndicator
