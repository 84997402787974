import { useState } from 'react'
import { useResizeObserver, useDebouncedEffect } from '@react-hookz/web'

const useDebouncedRect = (ref, delay = 500) => {
  const [x, setX] = useState({})
  const [rect, setRect] = useState({})

  useResizeObserver(ref, (e) => setX(e.contentRect))
  useDebouncedEffect(() => setRect(x), [x?.width, x?.height], delay)

  return rect
}

export default useDebouncedRect
