import { memo } from 'react'
import { Box, Grid } from 'theme-ui'
import { Heading } from '@monobits/components'
import { CloudImage } from '@boiler/cloudinary'

import Placeholder from './Placeholder'

const ImageCard = ({ children, title, image, year, price, index: [index, length] = [], sx, ...props }) => {
  return (
    <Box sx={{ variant: 'projects.card', ...sx }} data-even={index ? !!(index % 2) : null} {...props}>
      {image.url ? (
        <CloudImage sx={{ variant: 'card.image' }} {...image} />
      ) : (
        <Placeholder sx={{ variant: 'card.image' }} />
      )}

      <Grid sx={{ variant: 'projects.card.container' }}>
        <Heading as="h2" className="title">
          {title}
        </Heading>
        <div sx={{ variant: 'projects.card.foot' }}>
          {index && length && (
            <Heading as="h4" className="index">
              {index} / {length}
            </Heading>
          )}
          {year && (
            <Heading as="h3" className="year">
              {year}
            </Heading>
          )}
          {price && (
            <Heading as="h3" className="price">
              {price}&#8239;$
            </Heading>
          )}
        </div>
      </Grid>
    </Box>
  )
}

export default memo(ImageCard)
