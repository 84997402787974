import { inset } from '@monobits/core'

export default {
  __default: {
    overflow: 'hidden',
    bg: 'background',

    width: '100vw',
    height: 'calc(var(--vh-x, 1vh) * 100)',

    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr auto',
    gridGap: 2,

    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,

    zIndex: 'nav',
  },

  text: {
    pb: ['calc(var(--padding-l) * 2)', null, 0],
    minHeight: '100vh',
    '&[aria-hidden=true] > div': {
      opacity: 0,
      visibility: 'hidden',
    },
  },

  switch: {
    position: 'fixed',
    zIndex: 'nav',
    right: 1,

    'html:not(.has-scroll-smooth) &': { bottom: 1 },
    'html.has-scroll-smooth &': {
      top: 'calc(100vh - var(--padding-x))',
      '> button': { transform: 'translateY(-100.5%)' },
    },
  },

  medias: {
    size: '100%',
    minWidth: 0,
    minHeight: 0,
    display: 'flex',

    '.image': {
      size: '100%',
    },
  },

  navigation: {
    size: '2.5rem',
    p: '0.6em',
    border: 'var(--stroke-x) solid currentColor',
    borderRadius: '50%',

    '--pos': 'calc(var(--padding-x) / 2)',

    bg: 'background',

    '@media (hover: hover)': {
      '&:hover': {
        bg: 'text',
        color: 'background',
        borderColor: 'text',
      },
    },
    '@media (hover: none)': {
      '&:active': {
        bg: 'text',
        color: 'background',
        borderColor: 'text',
      },
    },

    transition:
      'border-color var(--ui-duration) var(--ui-ease),background-color var(--ui-duration) var(--ui-ease), color var(--ui-duration) var(--ui-ease)',

    position: 'absolute',
    top: '50%',
    zIndex: 'nav',

    transform: 'translateY(-50%)',

    '&[data-nav="next"]': {
      right: 'var(--pos)',
    },
    '&[data-nav="prev"]': {
      left: 'var(--pos)',
    },
  },

  head: {
    pl: [null, null, 'calc(4ch + var(--padding-xxl))'],
    pr: [null, null, 'calc(4ch + (var(--padding-l) * 3))'],

    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'auto auto 1fr',
    gridTemplateRows: ['auto auto', null, '1fr'],
    gridColumnGap: 'var(--padding-xxl)',
    gridRowGap: 1,

    '> *:last-of-type': {
      textAlign: 'center',
    },
  },
  foot: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: ['column-reverse', 'row', 'row'],

    '> a': {
      mt: ['var(--padding-s)', 0],
    },
  },
}
