import { Icon } from '@monobits/components'

import ArrowTop from '../../assets/svg/arrow-top.svg'
import ArrowLeft from '../../assets/svg/arrow-left.svg'
import ArrowRight from '../../assets/svg/arrow-right.svg'
import ArrowBottom from '../../assets/svg/arrow-bottom.svg'
import ArrowTopLeft from '../../assets/svg/arrow-top-left.svg'
import ArrowBottomLeft from '../../assets/svg/arrow-bottom-left.svg'
import ArrowTopRight from '../../assets/svg/arrow-top-right.svg'
import ArrowBottomRight from '../../assets/svg/arrow-bottom-right.svg'

import NormalArrowLeft from '../../assets/svg/normal-arrow-left.svg'
import NormalArrowRight from '../../assets/svg/normal-arrow-right.svg'

import Grid from '../../assets/svg/grid.svg'
import List from '../../assets/svg/list.svg'
import Bullet from '../../assets/svg/bullet.svg'
import Plus from '../../assets/svg/plus.svg'
import Cross from '../../assets/svg/cross.svg'
import Placeholder from '../../assets/svg/placeholder.svg'

const icons = {
  plus: Plus,
  cross: Cross,
  bullet: Bullet,
  grid: Grid,
  list: List,
  placeholder: Placeholder,
  'normal-arrow-left': NormalArrowLeft,
  'normal-arrow-right': NormalArrowRight,
  'arrow-top': ArrowTop,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-bottom': ArrowBottom,
  'arrow-top-left': ArrowTopLeft,
  'arrow-bottom-left': ArrowBottomLeft,
  'arrow-top-right': ArrowTopRight,
  'arrow-bottom-right': ArrowBottomRight,
}

const Icons = ({ name, ...props }) => name in icons && <Icon icon={icons[name]} {...props} />

export default Icons
